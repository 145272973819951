import { useNhostClient, useUserDefaultRole } from '@nhost/react'
import React, { ComponentProps } from 'react'
import { GET_BUSINESS_BY_ID } from '../../graphql/queries'
import { useBusinessId, useBusinessQuery } from '../../hooks'
import { SUPER_USER_ROLE } from '../../utils/constants'
import { Button } from '@8thday/react'
import { AddBusinessMembers } from './AddBusinessMembers'
import { BusinessMembers } from './BusinessMembers'
import { LoadingScreen } from './LoadingScreen'
import { useNavigate } from 'react-router-dom'
import { openNewTab } from '../../utils/general'

export interface DashboardProps extends ComponentProps<'div'> {}

export const Dashboard = ({ className = '', ...props }: DashboardProps) => {
  const nhost = useNhostClient()
  const isSuper = useUserDefaultRole() === SUPER_USER_ROLE
  const businessId = useBusinessId()

  const goTo = useNavigate()

  const { data } = useBusinessQuery(GET_BUSINESS_BY_ID)

  if (!data?.business_by_pk) {
    return <LoadingScreen />
  }

  return (
    <div className={`${className} p-4 flex flex-col items-stretch`} {...props}>
      <h1 className="text-center text-lg md:text-2xl mb-8 text-gray-800">
        {data.business_by_pk.name}
        {window.location.origin.includes('localhost') && localStorage.getItem('show-test-button') && (
          <Button
            className="inline-block mt-4"
            onClick={async () => console.log(await nhost.functions.call('test_endpoint', null, { useAxios: false }))}
          >
            Test
          </Button>
        )}
      </h1>
      {isSuper ? (
        <>
          <BusinessMembers className="mb-8" />
          <AddBusinessMembers />
        </>
      ) : (
        <Button
          variant="primary"
          className="mt-8 self-center"
          onClick={(e) => (e.metaKey ? openNewTab(`/b/${businessId}/websites`) : goTo(`websites`))}
        >
          Manage Your Websites
        </Button>
      )}
    </div>
  )
}
