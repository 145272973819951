import React, { ComponentProps, useState } from 'react'
import { PlusIcon } from '@heroicons/react/20/solid'
import { useBusinessId, useBusinessQuery } from '../../hooks'
import { Avatar } from '../atoms/Avatar'
import { GET_ALL_USERS_NOT_IN_BUSINESS } from '../../graphql/queries'
import { TextInput, Button } from '@8thday/react'
import { useNhostClient } from '@nhost/react'
import { INVITE_EXISTING_USER_TO_BUSINESS, INVITE_NEW_USER_TO_BUSINESS } from '../../graphql/mutations'
import { toast } from '../../utils/toasts'
import { Users } from '../../gql/graphql'
import { useApolloClient } from '@apollo/client'

export interface AddBusinessMembersProps extends ComponentProps<'div'> {}

export const AddBusinessMembers = ({ className = '', ...props }: AddBusinessMembersProps) => {
  const nhost = useNhostClient()
  const apolloClient = useApolloClient()
  const businessId = useBusinessId()
  const [emails, setEmails] = useState('')

  const { data, refetch } = useBusinessQuery(GET_ALL_USERS_NOT_IN_BUSINESS)

  const users = data?.users ?? []

  const addUser = async (user: Partial<Users>) => {
    await nhost.graphql
      .request(INVITE_EXISTING_USER_TO_BUSINESS, { businessId, userId: user.id }, { useAxios: false })
      .then(() => {
        toast.success({ message: `Successfully invited`, description: `${user.email}` })
      })
      .catch((e) => {
        toast.error({ message: `Error inviting ${user.email}`, description: e.message })
        return e instanceof Error ? e : new Error(JSON.stringify(e))
      })

    refetch({ businessId })
    apolloClient.reFetchObservableQueries()
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    const separatedEmails = emails.split(/[;\s,]+/)

    const results = await Promise.all(
      separatedEmails.map((email) =>
        nhost.graphql.request(INVITE_NEW_USER_TO_BUSINESS, { businessId, email }, { useAxios: false }).catch((e) => {
          toast.error({ message: `Error inviting ${email}`, description: e.message })
          return e instanceof Error ? e : new Error(JSON.stringify(e))
        })
      )
    )

    if (results.every((r) => !(r instanceof Error))) {
      toast.success({ message: `Success!`, description: `Users invited: ${separatedEmails.join(', ')}` })

      setEmails('')
    }

    refetch({ businessId })
    apolloClient.reFetchObservableQueries()
  }

  return (
    <div className={`${className} mx-auto w-full max-w-3xl`} {...props}>
      <div>
        <h2 className="mb-4 font-medium text-gray-500">Invite Business Members</h2>
        <form className="flex" onSubmit={onSubmit}>
          <TextInput
            className="grow"
            description="separate emails by comma, semicolon, or space"
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
            placeholder="teammate@your.biz"
          />
          <Button type="submit" variant="primary" className="ml-2 self-start">
            Send Invite
          </Button>
        </form>
      </div>
      {!!users.length && (
        <div className="mt-10">
          <h3 className="mb-4 font-medium text-gray-500">Add Members From Current PrimeSights Users</h3>
          <ul role="list" className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {users.map((user) => (
              <li key={user.id}>
                <button
                  type="button"
                  className="group flex w-full items-center justify-between space-x-3 rounded-full border border-gray-300 p-2 text-left shadow-sm bg-white hover:bg-primary-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => addUser(user)}
                >
                  <span className="flex min-w-0 flex-1 items-center space-x-3">
                    <span className="block flex-shrink-0 h-10 w-10">
                      <Avatar avatarUrl={user.avatarUrl} />
                    </span>
                    <span className="block min-w-0 flex-1">
                      <span className="block truncate text-sm font-medium text-gray-900">{user.displayName}</span>
                      <span className="block truncate text-sm font-medium text-gray-500">{user.email}</span>
                    </span>
                  </span>
                  <span className="inline-flex h-10 w-10 flex-shrink-0 items-center justify-center">
                    <PlusIcon className="h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
