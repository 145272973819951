import React from 'react'
import { createRoot } from 'react-dom/client'
import { AppShell } from './App'
import { NhostClient, NhostProvider } from '@nhost/react'
import { BrowserRouter } from 'react-router-dom'
import { NhostApolloProvider } from '@nhost/react-apollo'
import { Toaster } from 'react-hot-toast'

const nhost = new NhostClient({
  subdomain: process.env.REACT_APP_NHOST_SUBDOMAIN,
  region: process.env.REACT_APP_NHOST_REGION,
  clientStorageType: 'cookie',
})

const root = createRoot(document.getElementById('app')!)

root.render(
  <NhostProvider nhost={nhost}>
    <NhostApolloProvider nhost={nhost}>
      <BrowserRouter>
        <Toaster position="top-right" />
        <AppShell />
      </BrowserRouter>
    </NhostApolloProvider>
  </NhostProvider>
)
