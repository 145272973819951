import clsx from 'clsx'
import React, { ComponentProps } from 'react'
// @ts-ignore
import PrimeSightsSVG from '../../images/primesights.svg'

export interface PrimeSightsBackgroundProps extends ComponentProps<'div'> {}

export const PrimeSightsBackground = ({ className = '', ...props }: PrimeSightsBackgroundProps) => {
  return (
    <div className={clsx(className, 'absolute inset-0 -z-50 text-primary-100 opacity-50 overflow-hidden')} {...props}>
      <PrimeSightsSVG />
    </div>
  )
}
