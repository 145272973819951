import { graphql } from '../gql'

export const UPDATE_USER_METADATA = graphql(`
  mutation UpdateUserProfile($id: uuid!, $metadata: jsonb!, $displayName: String) {
    updateUser(pk_columns: { id: $id }, _set: { displayName: $displayName }, _append: { metadata: $metadata }) {
      id
    }
  }
`)

export const UPDATE_WEBSITE_ACCESS = graphql(`
  mutation updateWebsiteAccess($id: uuid!, $permissions: jsonb!) {
    update_website_access_by_pk(pk_columns: { id: $id }, _set: { permissions: $permissions }) {
      id
    }
  }
`)

export const CREATE_WEBSITE_ACCESS = graphql(`
  mutation createWebsiteAccess($websiteId: uuid!, $userId: uuid!, $permissions: jsonb!) {
    insert_website_access_one(object: { website_id: $websiteId, user_id: $userId, permissions: $permissions }) {
      id
    }
  }
`)

export const DELETE_WEBSITE_ACCESS = graphql(`
  mutation deleteWebsiteAccess($id: uuid!) {
    delete_website_access_by_pk(id: $id) {
      id
    }
  }
`)

export const INVITE_NEW_USER_TO_BUSINESS = graphql(`
  mutation CreateUser($email: citext!, $businessId: uuid!) {
    insertUser(
      object: {
        email: $email
        locale: "en"
        roles: { data: [{ role: "user" }, { role: "me" }] }
        user_businesses: { data: { business_id: $businessId } }
      }
    ) {
      id
    }
  }
`)

export const INVITE_EXISTING_USER_TO_BUSINESS = graphql(`
  mutation CreateBusinessUser($userId: uuid!, $businessId: uuid!) {
    insert_business_user_one(object: { user_id: $userId, business_id: $businessId }) {
      id
    }
  }
`)

export const CREATE_BUSINESS = graphql(`
  mutation CreateBusiness($name: String!) {
    insert_business_one(object: { name: $name }) {
      id
      name
      created_at
      updated_at
    }
  }
`)

export const DELETE_BUSINESS_USER = graphql(`
  mutation DeleteBusinessUser($userId: uuid!, $businessId: uuid!) {
    delete_business_user(where: { user_id: { _eq: $userId }, business_id: { _eq: $businessId } }) {
      affected_rows
    }
  }
`)

export const CREATE_WIDGET_INSTALL = graphql(`
  mutation createWidgetInstall($widgetSlug: String!, $siteId: String!, $data: jsonb!) {
    insert_widget_install_one(object: { widget_slug: $widgetSlug, site_identifier: $siteId, data: $data }) {
      id
    }
  }
`)

export const UPDATE_PRODUCT_MAPPING = graphql(`
  mutation updateProductMapping($id: uuid!, $productMapping: jsonb!) {
    update_widget_install_by_pk(pk_columns: { id: $id }, _append: { data: { productMapping: $productMapping } }) {
      id
    }
  }
`)
