import React, { ComponentProps } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Site } from '@dudadev/partner-api/dist/types/lib/sites/types'
import { useBusinessSubscription } from '../../hooks/useBusinessQuery'
import { SUB_TO_WEBSITE_ACCESS } from '../../graphql/queries'
import { Button } from '@8thday/react'
import { Bars3Icon, EyeIcon, PencilIcon } from '@heroicons/react/24/outline'
import { openNewTab, openSSOLink } from '../../utils/general'
import { useNhostClient, useUserData } from '@nhost/react'
import { TeamMember } from './TeamMember'
import { SUPER_USER_ROLE } from '../../utils/constants'
import { hasDudaPermission } from '../../utils/permissions-matrix'
import clsx from 'clsx'

export interface WebsiteManagerProps extends ComponentProps<'div'> {}

export const WebsiteManager = ({ className = '', ...props }: WebsiteManagerProps) => {
  const nhost = useNhostClient()
  const site = useOutletContext<Site>()
  const userData = useUserData()

  const isSuper = userData?.defaultRole === SUPER_USER_ROLE

  const { data } = useBusinessSubscription(SUB_TO_WEBSITE_ACCESS, {
    variables: { websiteId: site?.external_uid },
    skip: !site?.external_uid,
  })

  const me = data?.business_user.find((b) => b?.user?.id === userData?.id)?.user
  const sitePermissions = me?.website_access_memberships?.find((m) => m.website_id === site?.external_uid)?.permissions

  return (
    <div className={`${className} h-full overflow-y-auto website-manager-grid gap-4`} {...props}>
      <div className="website-manager-title">
        <h3 className="text-center text-primary-700 text-xl mb-4">{site.site_domain ?? site.site_default_domain}</h3>
      </div>
      <div
        className={clsx('relative rounded-lg website-manager-preview lg:self-start', {
          'overflow-hidden': site.thumbnail_url,
        })}
      >
        <img src={site.thumbnail_url} className="w-full h-auto lg:h-min object-cover object-top lg:object-contain" />
        <div
          className={clsx('absolute h-full inset-0 flex pb-4', {
            'bg-gradient-to-t from-gray-600': site.thumbnail_url,
          })}
        >
          <div className="flex flex-wrap justify-evenly items-end w-full mt-auto space-y-2">
            <Button
              PreIcon={EyeIcon}
              onClick={() => {
                openNewTab(site.preview_site_url)
              }}
            >
              Preview Site
            </Button>
            {(isSuper ||
              hasDudaPermission(['BLOG', 'SITE_COMMENTS', 'LIMITED_EDITING', 'EDIT'], 'OR', sitePermissions)) && (
              <Button
                variant="primary"
                PreIcon={PencilIcon}
                onClick={() => {
                  openSSOLink({ nhost, site_name: site.site_name, target: 'EDITOR' })
                }}
              >
                Site Editor
              </Button>
            )}
            {site.publish_status === 'PUBLISHED' &&
              (isSuper || hasDudaPermission(['STATS_TAB'], 'AND', sitePermissions)) && (
                <Button
                  variant="primary"
                  PreIcon={Bars3Icon}
                  onClick={() => {
                    openSSOLink({ nhost, site_name: site.site_name, target: 'STATS' })
                  }}
                >
                  Site Stats
                </Button>
              )}
          </div>
        </div>
      </div>
      {isSuper ? (
        <div className="website-manager-access">
          <h3 className="text-center text-xl text-gray-800 mb-4">Team Access</h3>
          {data?.business_user?.map(({ user }) => (
            <TeamMember className="mb-4" key={user.id} user={user} site={site} />
          ))}
          {!data?.business_user?.length && (
            <p className="text-center text-gray-700">No members have joined this business yet.</p>
          )}
        </div>
      ) : (
        <div className="website-manager-access">
          <h3 className="text-center text-xl text-gray-800 mb-4">Your Access</h3>
          {me && <TeamMember readOnly className="mb-4" user={me} site={site} />}
        </div>
      )}
    </div>
  )
}
