import { useAuthQuery } from '@nhost/react-apollo'
import React, { ComponentProps } from 'react'
import { NavLink, Outlet, useParams } from 'react-router-dom'
import { GET_WIDGET_LIST } from '../../graphql'
import { Card } from '@8thday/react'

export interface WidgetGridProps extends ComponentProps<'div'> {}

export const WidgetGrid = ({ className = '', ...props }: WidgetGridProps) => {
  const { widgetSlug } = useParams()
  const { data } = useAuthQuery(GET_WIDGET_LIST)

  const widgets = data?.web_widget ?? []

  const widget = widgets.find((w) => w.slug === widgetSlug)

  return (
    <div className={`${className} flex flex-col h-screen p-4`} {...props}>
      {!widget && (
        <>
          <h2 className="text-center text-xl text-primary-800 mb-8">Web Widget Management</h2>
          <ul className="flex flex-wrap gap-4">
            {widgets.map((w) => (
              <li className="max-w-sm" key={w.slug}>
                <NavLink to={w.slug}>
                  <Card>{w.name}</Card>
                </NavLink>
              </li>
            ))}
          </ul>
        </>
      )}
      <Outlet context={{ widget }} />
    </div>
  )
}
