import { useAuthQuery } from '@nhost/react-apollo'
import React, { ComponentProps } from 'react'
import { NavLink } from 'react-router-dom'
import { GET_ALL_USERS } from '../../graphql/queries'
import { Avatar } from '../atoms/Avatar'
import { Badge } from '../atoms/Badge'

export interface AllUsersProps extends ComponentProps<'div'> {}

export const AllUsers = ({ className = '', ...props }: AllUsersProps) => {
  const { data } = useAuthQuery(GET_ALL_USERS)

  const users = data?.users ?? []

  return (
    <div className={`${className} p-4`} {...props}>
      <h1 className="text-center text-lg md:text-2xl mb-4">All Users</h1>
      <ul role="list" className="divide-y divide-gray-200">
        {users.map((user) => (
          <li key={user.id} className="py-4">
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0 h-8 w-8">
                <Avatar avatarUrl={user.avatarUrl} />
              </div>
              <div className="min-w-0 flex-1">
                <p className="truncate text-sm font-medium text-gray-900">{user.displayName}</p>
                <p className="truncate text-sm text-gray-500">{user.email}</p>
              </div>
              <div className="ml-auto group">
                <NavLink className="group" to={user.id}>
                  <Badge
                    shadow
                    size="medium"
                    className="group-hover:shadow-lg translate-y-0 group-hover:-translate-y-1 group-focus:translate-y-0 group-focus:shadow"
                  >
                    View
                  </Badge>
                </NavLink>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
