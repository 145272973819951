import { ArrowLeftIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useAuthQuery } from '@nhost/react-apollo'
import React, { ComponentProps } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { GET_USER_BY_ID } from '../../graphql/queries'
import { SUPER_USER_ROLE } from '../../utils/constants'
import { displayRole, formatDate, formatDateTime } from '../../utils/general'
import { Avatar } from '../atoms/Avatar'
import { Badge } from '../atoms/Badge'
import { DescriptionListItem } from '../atoms/DescriptionListItem'
import { LoadingScreen } from './LoadingScreen'

export interface UserDashboardProps extends ComponentProps<'div'> {}

export const UserDashboard = ({ className = '', ...props }: UserDashboardProps) => {
  const goTo = useNavigate()
  const { userId } = useParams()

  const { data, loading } = useAuthQuery(GET_USER_BY_ID, { variables: { userId } })

  const user = data?.user

  const isSuper = user?.defaultRole === SUPER_USER_ROLE

  if (!user && !loading) {
    goTo(`/all-users`)
    return <LoadingScreen />
  }

  if (!user || loading) {
    return <LoadingScreen />
  }

  return (
    <div className={`${className} relative p-4`} {...props}>
      <NavLink to="/all-users" className="absolute top-2 left-2 md:top-4 md:left-4 flex-center text-gray-600">
        <ArrowLeftIcon className="h-5 w-5 mr-1" />
        All Users
      </NavLink>
      <div className="mx-auto my-8 h-24 w-24">
        <Avatar avatarUrl={user.avatarUrl} />
      </div>
      <h1 className="pb-4 text-center text-xl border-b border-gray-200">{user.displayName}</h1>
      <div className="mx-auto mt-6 pb-4 max-w-5xl px-4 sm:px-6 lg:px-8 border-b border-gray-200">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
          <DescriptionListItem label="Email">
            <a href={`mailto:${user.email}`} className="text-primary-600 hover:text-primary-400">
              {user.email}
            </a>
          </DescriptionListItem>
          <DescriptionListItem label="Email Verified">
            {user.emailVerified ? (
              <CheckIcon className="h-5 w-5 text-green-500" />
            ) : (
              <XMarkIcon className="h-5 w-5 text-red-500" />
            )}
          </DescriptionListItem>
          <DescriptionListItem label="Phone">{user.phoneNumber ?? '--'}</DescriptionListItem>
          {user.phoneNumber && (
            <DescriptionListItem label="Phone Verified">
              {user.phoneNumberVerified ? (
                <CheckIcon className="h-5 w-5 text-green-500" />
              ) : (
                <XMarkIcon className="h-5 w-5 text-red-500" />
              )}
            </DescriptionListItem>
          )}
          <DescriptionListItem label="Last Login">
            {user.lastSeen ? formatDateTime(user.lastSeen) : 'Never'}
          </DescriptionListItem>
          <DescriptionListItem label="Joined PrimeSights">{formatDate(user.createdAt)}</DescriptionListItem>
          <DescriptionListItem label="Role">
            <Badge color={isSuper ? 'green' : 'gray'}>{displayRole(user.defaultRole)}</Badge>
          </DescriptionListItem>
          <DescriptionListItem label="Business Memberships">
            {isSuper ? (
              'All Businesses'
            ) : (
              <ul className="flex flex-wrap">
                {user.user_businesses.length
                  ? user.user_businesses.map((ub) => (
                      <li key={ub.id} className="mr-2">
                        <NavLink to={`/b/${ub.business.id}`}>
                          <Badge color="primary">{ub.business.name}</Badge>
                        </NavLink>
                      </li>
                    ))
                  : 'None'}
              </ul>
            )}
          </DescriptionListItem>
        </dl>
      </div>
    </div>
  )
}
