import { useApolloClient } from '@apollo/client'
import { PlusIcon } from '@heroicons/react/24/outline'
import { useNhostClient, useUserDefaultRole } from '@nhost/react'
import React, { ComponentProps, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Mutation_Root } from '../../gql/graphql'
import { CREATE_BUSINESS } from '../../graphql'
import { Button, TextInput } from '@8thday/react'
import { SUPER_USER_ROLE } from '../../utils/constants'
import { PrimeSightsBackground } from './PrimeSightsBackground'

export interface HomeProps extends ComponentProps<'div'> {}

export const Home = ({ className = '', ...props }: HomeProps) => {
  const nhost = useNhostClient()
  const apollo = useApolloClient()

  const isSuper = useUserDefaultRole() === SUPER_USER_ROLE

  const [name, setName] = useState('')

  const goTo = useNavigate()

  return (
    <div className={`${className} h-screen flex-center flex-col`} {...props}>
      <PrimeSightsBackground />
      <h1 className="mb-4 text-3xl text-primary-600 font-semibold">Welcome to Prime Sights</h1>
      <p className="text-gray-400 mb-8">A Strafford Media Project</p>
      {isSuper && (
        <form
          className="flex flex-col md:flex-row items-center"
          onSubmit={async (e) => {
            e.preventDefault()
            e.stopPropagation()

            if (!name) return

            const res = await nhost.graphql.request<Mutation_Root>(CREATE_BUSINESS, { name }, { useAxios: false })

            if (!res.error && res.data?.insert_business_one?.id) {
              await apollo.reFetchObservableQueries()
              goTo(`/b/${res.data.insert_business_one.id}`)
            }
          }}
        >
          <TextInput
            className="mb-2 md:mb-0 md:mr-2"
            placeholder="My Business"
            value={name}
            onChange={(e) => setName(e.target.value)}
            collapseDescriptionArea
          />
          <Button type="submit" disabled={name.length < 4} PreIcon={PlusIcon}>
            Create A Business
          </Button>
        </form>
      )}
    </div>
  )
}
