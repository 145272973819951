import { useAuthSubscription } from '@nhost/react-apollo'
import React, { ComponentProps } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { WIDGET_INSTALL_SUB } from '../../graphql'
import { LoadingScreen } from './LoadingScreen'
import { SquareCheckoutButton } from './widget-installations/SquareCheckoutButton'

export interface WidgetInstallProps extends ComponentProps<'div'> {}

export const WidgetInstall = ({ className = '', ...props }: WidgetInstallProps) => {
  const { siteId = '', widgetSlug = '' } = useParams()
  const goTo = useNavigate()

  const { data, loading } = useAuthSubscription(WIDGET_INSTALL_SUB, {
    variables: { siteId, widgetSlug },
    skip: !siteId || !widgetSlug,
  })

  const widgetInstall = data?.widget_install?.[0]

  if (loading) {
    return <LoadingScreen />
  }

  if (!widgetInstall) {
    goTo(`/web-widgets/${widgetSlug}`)
    return null
  }

  switch (widgetSlug) {
    case 'square-subscription-checkout-button':
      return <SquareCheckoutButton widgetInstall={widgetInstall} />
    default:
      console.error('no component found for widget slug??')
      return null
  }
}
