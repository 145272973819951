import React, { useState } from 'react'
import { useNhostClient, useUserData } from '@nhost/react'
import { TextInput, Button } from '@8thday/react'
import { useMutation } from '@apollo/client'
import { UPDATE_USER_METADATA } from '../../graphql/mutations'
import { Avatar } from '../atoms/Avatar'
import { SUPER_USER_ROLE } from '../../utils/constants'
import { toast } from '../../utils/toasts'
import { Badge } from '../atoms/Badge'
import { displayRole } from '../../utils/general'
import { PrimeSightsBackground } from './PrimeSightsBackground'

export const Profile = () => {
  const nhost = useNhostClient()
  const user = useUserData()
  const isSuper = user?.defaultRole === SUPER_USER_ROLE

  const [firstName, setFirstName] = useState((user?.metadata?.firstName as string) ?? '')
  const [lastName, setLastName] = useState((user?.metadata?.lastName as string) ?? '')

  const [updateProfile] = useMutation(UPDATE_USER_METADATA, {
    variables: { id: user?.id, metadata: { firstName, lastName }, displayName: `${firstName} ${lastName}` },
    context: { headers: { 'x-hasura-role': 'me' } },
    onCompleted() {
      nhost.auth.refreshSession()
    },
  })

  const formDirty = firstName !== (user?.metadata?.firstName ?? '') || lastName !== (user?.metadata?.lastName ?? '')

  return (
    <div>
      <PrimeSightsBackground />
      <div className="border-b border-gray-200 p-2 md:p-4 flex flex-col">
        <div className="flex justify-between">
          <h1 id="message-heading" className="text-xl md:text-2xl font-medium text-gray-900">
            Profile
          </h1>
          <div className="mt-0 ml-6 flex-shrink-0 justify-start">
            <Badge color="green" size="large">
              {displayRole(user?.defaultRole ?? '')}
            </Badge>
          </div>
        </div>
        <p className="mt-1 truncate text-base text-gray-500 italic">{user?.email}</p>
      </div>
      <div className="w-full flex-center flex-col space-y-2 md:space-y-4 p-2 md:p-4">
        <Avatar className="w-32 h-32 md:w-48 md:h-48 bg-white" avatarUrl={user?.avatarUrl} />
        <div className="w-full flex-center flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-2">
          <TextInput
            className="min-w-[66%] md:min-w-0 md:max-w-96 md:grow"
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextInput
            className="min-w-[66%] md:min-w-0 md:max-w-96 md:grow"
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <Button
          variant="primary"
          disabled={!formDirty}
          onClick={async () => {
            if (formDirty) {
              const result = await updateProfile().catch((err) =>
                err instanceof Error ? err : new Error(JSON.stringify(err))
              )

              if (result instanceof Error) {
                return console.error(result)
              }

              toast.success({ message: 'Profile updated!' })
            }
          }}
        >
          Save Profile
        </Button>
      </div>
      {isSuper && (
        <div className="flex-center flex-col mt-12">
          <h3 className="text-center mb-4 text-lg">Refresh Website Permissions</h3>
          <div className="flex-center">
            <Button
              variant="primary"
              onClick={async () => {
                const result = await nhost.functions
                  .call<{ count: number; permissions: string[] }>(
                    'duda/permissions/super_user',
                    { superUserId: user.id },
                    { useAxios: false }
                  )
                  .catch((err) => (err instanceof Error ? err : new Error(JSON.stringify(err))))

                if (result instanceof Error) {
                  console.error(result)
                  return toast.error({ description: result.message })
                }

                const count = result.res?.data.count

                toast.success({
                  message: count ? `${count} permissions fixed` : 'Done!',
                  description: count
                    ? `Permissions used: ${result.res?.data.permissions.join(', ')}`
                    : 'We checked, and nothing needed fixing.',
                })
              }}
            >
              Fixes Only
            </Button>
            <Button
              className="ml-2"
              onClick={async () => {
                const result = await nhost.functions
                  .call<{ count: number; permissions: string[] }>(
                    'duda/permissions/super_user',
                    { superUserId: user.id, force: true },
                    { useAxios: false }
                  )
                  .catch((err) => (err instanceof Error ? err : new Error(JSON.stringify(err))))

                if (result instanceof Error) {
                  console.error(result)
                  return toast.error({ description: result.message })
                }

                toast.success({
                  message: `${result.res?.data.count} websites updated`,
                  description: `Permissions used: ${result.res?.data.permissions.join(', ')}`,
                })
              }}
            >
              Force All
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
