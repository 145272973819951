import React, { ComponentProps } from 'react'

export interface LoadingScreenProps extends ComponentProps<'main'> {}

export const LoadingScreen = ({ className = '', ...props }: LoadingScreenProps) => {
  return (
    <main className={`${className} h-screen flex-center bg-gray-50`} {...props}>
      <div className="text-primary-500 animate-pulse">Loading...</div>
    </main>
  )
}
