import { useApolloClient } from '@apollo/client'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useNhostClient } from '@nhost/react'
import React, { ComponentProps } from 'react'
import { Users } from '../../gql/graphql'
import { DELETE_BUSINESS_USER, GET_BUSINESS_MEMBERS } from '../../graphql'
import { useBusinessId, useBusinessQuery } from '../../hooks'
import { SUPER_USER_ROLE } from '../../utils/constants'
import { displayBusinessRole } from '../../utils/general'
import { Avatar } from '../atoms/Avatar'
import { Badge } from '../atoms/Badge'
import { IconButton } from '../atoms/IconButton'

export interface BusinessMembersProps extends ComponentProps<'ul'> {}

export const BusinessMembers = ({ className = '', ...props }: BusinessMembersProps) => {
  const businessId = useBusinessId()
  const nhost = useNhostClient()
  const apolloClient = useApolloClient()
  const { data } = useBusinessQuery(GET_BUSINESS_MEMBERS)

  const users = data?.users ?? []

  const deleteBusinessUser = async (user: Partial<Users>) => {
    await nhost.graphql.request(DELETE_BUSINESS_USER, { businessId, userId: user.id }, { useAxios: false })

    apolloClient.reFetchObservableQueries()
  }

  return (
    <ul role="list" className={`${className} divide-y divide-gray-200 max-w-3xl w-full mx-auto`} {...props}>
      <h2 className="text-center mb-4 text-lg font-medium text-gray-900">Business Members</h2>
      {users.map((user) => {
        const memberIsSuper = user.defaultRole === SUPER_USER_ROLE
        return (
          <li key={user.email} className="flex items-center py-4 group">
            <div className="h-10 w-10 flex-shrink-0">
              <Avatar avatarUrl={user.avatarUrl} />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900 truncate">{user.displayName}</p>
              <p className="text-sm text-gray-500">{user.email}</p>
            </div>
            <Badge className="ml-auto" size="medium" color={memberIsSuper ? 'green' : 'gray'}>
              {displayBusinessRole(user.defaultRole)}
            </Badge>
            {!memberIsSuper && (
              <IconButton
                srLabel="Delete Business Member"
                className="hidden group-hover:flex"
                colorClass="text-gray-400 hover:text-red-500 focus:text-red-600"
                icon={XMarkIcon}
                onClick={() => deleteBusinessUser(user)}
              />
            )}
          </li>
        )
      })}
    </ul>
  )
}
