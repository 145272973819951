import React, { Fragment, MouseEvent, useEffect, useLayoutEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import clsx from 'clsx'
import { NavLink, useNavigate } from 'react-router-dom'
import { CompanyLogo } from './components/atoms/CompanyLogo'
import { useAuthenticationStatus, useUserData } from '@nhost/react'
import { LoginScreen } from './components/molecules/LoginScreen'
import { LoadingScreen } from './components/molecules/LoadingScreen'
import { useArtificialLoader, useBusinessId, useQueryToaster } from './hooks'
import { Router } from './app-state/Router'
import { NavLinkList } from './app-state/NavLinkList'
import { Avatar } from './components/atoms/Avatar'
import { useAuthQuery } from '@nhost/react-apollo'
import { GET_BUSINESSES } from './graphql/queries'
import { openNewTab } from './utils/general'
import { validate } from 'uuid'
import { CompanyName } from './components/atoms/CompanyName'

const emptyBusinesses = []

export const AppShell = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const loading = useArtificialLoader()
  const { isLoading } = useAuthenticationStatus()

  const user = useUserData()

  const { data, loading: businessesLoading, called } = useAuthQuery(GET_BUSINESSES)
  const businesses = data?.business ?? emptyBusinesses

  const businessId = useBusinessId()

  const goTo = useNavigate()

  const goHome = (e: MouseEvent) => {
    if (e.metaKey) {
      openNewTab('/')
    } else {
      goTo('/')
    }
  }

  useEffect(() => {
    if (
      businessId &&
      (!validate(businessId) || (!businessesLoading && called && businesses.every((b) => b.id !== businessId)))
    ) {
      goTo('/')
    }
  }, [businesses, businessId, businessesLoading, called])

  useLayoutEffect(() => {
    const role = user?.defaultRole || 'user'

    document.body.classList.replace('superuser', role)
    document.body.classList.replace('user', role)
  }, [user?.defaultRole])

  useQueryToaster()

  if (isLoading || loading) {
    return <LoadingScreen />
  }

  if (!user) {
    return <LoginScreen />
  }

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-primary-700">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <div className="flex flex-shrink-0 items-center px-4">
                    <CompanyLogo
                      role="button"
                      tabIndex={0}
                      className="cursor-pointer w-12 h-12 mr-2"
                      onClick={(e) => goHome(e)}
                    />
                    <CompanyName />
                  </div>
                  <NavLinkList businesses={businesses} onClick={() => setSidebarOpen(false)} />
                </div>
                <ProfileNavLink onClick={() => setSidebarOpen(false)} />
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col bg-primary-700">
          <div className="flex flex-1 flex-col overflow-y-auto pb-4">
            <div className="sticky top-0 bg-primary-700 border-b border-primary-800 flex flex-shrink-0 items-center px-4 py-2">
              <CompanyLogo
                role="button"
                tabIndex={0}
                className="cursor-pointer w-12 h-12 mr-2"
                onClick={(e) => goHome(e)}
              />
              <CompanyName />
            </div>
            <NavLinkList businesses={businesses} />
          </div>
          <ProfileNavLink onClick={() => setSidebarOpen(false)} />
        </div>
      </div>
      <div className="flex flex-1 flex-col md:pl-64">
        <div className="fixed top-0 z-10 bg-gray-100 md:hidden shadow w-full flex">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open navigation sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <CompanyLogo
            role="button"
            type="waves"
            tabIndex={0}
            onClick={(e) => goHome(e)}
            className="absolute left-1/2 -translate-x-1/2 mt-1 cursor-pointer h-8"
          />
        </div>
        <main className="h-content mt-12 md:h-screen md:mt-0">
          <Router />
        </main>
      </div>
    </div>
  )
}

interface ProfileNavLinkProps {
  onClick?(): void
}

const ProfileNavLink = ({ onClick }: ProfileNavLinkProps) => {
  const user = useUserData()

  return (
    user && (
      <NavLink
        to={`/profile`}
        className={({ isActive }) =>
          clsx(
            'group flex w-full p-4 pr-2 items-center border-t border-primary-800',
            isActive ? 'bg-primary-800' : 'hover:bg-primary-600 hover:bg-opacity-75'
          )
        }
        onClick={onClick}
      >
        <Avatar className="h-12 w-12 md:h-10 md:w-10 min-h-12 min-w-12 md:min-h-10 md:min-w-10" />
        <div className="ml-3 min-w-0">
          <div className="text-lg md:text-base font-medium text-white truncate">{user.displayName ?? user.email}</div>
          <div className="text-base md:text-sm font-medium text-primary-200 group-hover:text-white">View profile</div>
        </div>
      </NavLink>
    )
  )
}
