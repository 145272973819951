import React, { ComponentProps } from 'react'
import { Permissions } from '@dudadev/partner-api/dist/types/lib/accounts/types'
import clsx from 'clsx'
import { validatePermission } from '../../utils/permissions-matrix'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

const permissionList: { value: Permissions | 'ALL'; label: string; description: string }[] = [
  { value: 'ALL', label: 'All', description: 'Toggle all scopes.' },
  { value: 'STATS_TAB', label: 'Stats', description: 'Access to statistics for this website.' },
  {
    value: 'BLOG',
    label: 'Blog',
    description: 'Give access to write new posts, edit existing ones, and manage blog settings.',
  },
  {
    value: 'E_COMMERCE',
    label: 'E-commerce',
    description: 'Manage catalog, view orders, and control store settings.',
  },
  { value: 'SITE_COMMENTS', label: 'Site Comments', description: 'Add, edit, and delete site comments.' },
  { value: 'LIMITED_EDITING', label: 'Limited Editing', description: 'Edit existing widget content.' },
  { value: 'BACKUPS', label: 'Backups', description: 'Create, restore, and delete backups.' },
  { value: 'RESET', label: 'Reset', description: 'Reset and pick a new template for an existing site.' },
  {
    value: 'USE_APP',
    label: 'Use Apps',
    description: 'Use all apps which are added to a site (requires Editing permissions).',
  },
  {
    value: 'CLIENT_MANAGE_FREE_APPS',
    label: 'Manage Free Apps',
    description: 'Add, remove, and use free apps. Use paid apps added by other users (requires Editing permissions).',
  },
  {
    value: 'CONTENT_LIBRARY',
    label: 'Content Library',
    description: 'Manage site Content Library including images, business info, form responses, etc.',
  },
  {
    value: 'EDIT',
    label: 'Editing',
    description:
      'Allow the user to perform all possible edits to the website, such as delete and move elements, and add new content.',
  },
  { value: 'SEO', label: 'SEO', description: 'Set SEO settings on the site or page level.' },
  {
    value: 'CUSTOM_DOMAIN',
    label: 'Custom Domain',
    description: 'Set or edit the domain of a website. Can only be accessed on published websites.',
  },
  { value: 'REPUBLISH', label: 'Republish', description: 'Update the live site with all changes made in the editor.' },
  { value: 'INSITE', label: 'Insite', description: 'Add, edit, or delete existing website personalization rules.' },
  { value: 'ADD_FLEX', label: 'Flex', description: 'Add Flex templates and sections in the editor.' },
  {
    value: 'PUSH_NOTIFICATIONS',
    label: 'Push Notifications',
    description: 'Manage Push Notifications for 3rd party store mobile apps',
  },
  {
    value: 'DEV_MODE',
    label: 'Dev Mode',
    description:
      'Allow direct access to the HTML & CSS of the website. Without this permission, the user can still use the HTML embed widget (with Editing permissions).',
  },
  { value: 'PUBLISH', label: 'Publish', description: 'Publish the site for the first time.' },
  {
    value: 'EDIT_CONNECTED_DATA',
    label: 'Edit Connected Data',
    description: 'Edit connected content from the Connected Data popup.',
  },
  {
    value: 'MANAGE_CONNECTED_DATA',
    label: 'Manage Connected Data',
    description: 'Add and manage widgets with Connected Data. Create and manage Dynamic Pages.',
  },
]

type SelectedPermissions = Partial<Record<Permissions, boolean>>

export interface WebsitePermissionsChecklistProps extends ComponentProps<'div'> {
  permissions: Permissions[]
  onPermsChange(perms: Permissions[]): void
  readOnly?: boolean
}

export const WebsitePermissionsChecklist = ({
  id,
  className = '',
  permissions = [],
  onPermsChange,
  readOnly = false,
  ...props
}: WebsitePermissionsChecklistProps) => {
  const selected = permissions.reduce<SelectedPermissions>((map, perm) => ({ ...map, [perm]: true }), {})

  const allSelected = permissions.length === permissionList.length - 1

  if (readOnly && !permissions.length) {
    return (
      <div className="text-center text-gray-800">
        Contact your administrator or support to gain access to this website.
      </div>
    )
  }

  return (
    <div className={`${className} border-b border-gray-200`} {...props}>
      <h3 className="border-b border-gray-200 pb-2">Website Access Scopes</h3>
      <ul className="divide-y divide-gray-200">
        {permissionList.map((permission) => {
          const permIsSelected = permission.value === 'ALL' ? allSelected : selected[permission.value] ?? false
          const validated = permission.value === 'ALL' || validatePermission(permission.value, selected)
          const permIsValid = !permIsSelected || validated
          const permDisabled = !permIsSelected && !validated

          if (permission.value === 'ALL' && readOnly) {
            return null
          }

          return (
            <div
              key={permission.value}
              className={clsx('relative flex items-start py-2', {
                'opacity-60': !readOnly && permDisabled,
              })}
            >
              <label
                htmlFor={`${permission.value}-${id}`}
                className={clsx('min-w-0 flex-1 text-sm', { 'cursor-pointer': !permDisabled })}
              >
                <span className="font-medium text-gray-700">{permission.label}</span>
                <p id={`${permission.value}-description`} className="text-gray-500">
                  {permission.description}
                </p>
              </label>
              <div className="ml-3 flex h-5 items-center">
                {!permIsValid && <ExclamationTriangleIcon className="h-4 w-4 text-yellow-600 mr-4" />}
                <input
                  id={`${permission.value}-${id}`}
                  aria-describedby={`${permission.value}-description`}
                  name={permission.value}
                  checked={permIsSelected}
                  readOnly={readOnly}
                  disabled={readOnly || permDisabled}
                  onChange={(e) => {
                    const checked = e.target.checked

                    if (permission.value === 'ALL') {
                      onPermsChange(
                        checked
                          ? permissionList
                              .filter((p) => p.value !== 'ALL')
                              .map<Permissions>((p) => p.value as Permissions)
                          : []
                      )
                    } else if (checked) {
                      onPermsChange?.(permissions.concat([permission.value]))
                    } else {
                      onPermsChange?.(permissions.filter((p) => p !== permission.value))
                    }
                  }}
                  type="checkbox"
                  className={clsx(
                    'h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500 enabled:cursor-pointer',
                    { 'opacity-60': readOnly }
                  )}
                />
              </div>
            </div>
          )
        })}
      </ul>
    </div>
  )
}
