import { graphql } from '../gql'

export const GET_BUSINESSES = graphql(`
  query GetBusinesses {
    business {
      id
      name
    }
  }
`)

export const GET_BUSINESS_BY_ID = graphql(`
  query GetBusinessById($businessId: uuid!) {
    business_by_pk(id: $businessId) {
      id
      name
    }
  }
`)

export const SUB_TO_BUSINESS_BY_ID = graphql(`
  subscription SubBusinessById($businessId: uuid!) {
    business_by_pk(id: $businessId) {
      id
      name
      integrations {
        id
        enabled
        slug
      }
    }
  }
`)

export const GET_ALL_USERS = graphql(`
  query AllUsers {
    users(order_by: { email: asc }) {
      id
      email
      displayName
      disabled
      avatarUrl
      defaultRole
      emailVerified
      lastSeen
    }
  }
`)

export const GET_ALL_USERS_NOT_IN_BUSINESS = graphql(`
  query AllUsersNotInBusiness($businessId: uuid!) {
    users(
      order_by: { email: asc }
      where: { defaultRole: { _eq: "user" }, _not: { user_businesses: { business_id: { _eq: $businessId } } } }
    ) {
      id
      email
      displayName
      disabled
      avatarUrl
      defaultRole
      emailVerified
      lastSeen
    }
  }
`)

export const GET_BUSINESS_MEMBERS = graphql(`
  query BusinessMembers($businessId: uuid!) {
    users(
      order_by: { email: asc }
      where: {
        _or: [{ user_businesses: { business_id: { _eq: $businessId } } }, { defaultRole: { _eq: "superuser" } }]
      }
    ) {
      id
      email
      displayName
      disabled
      avatarUrl
      defaultRole
      emailVerified
      lastSeen
    }
  }
`)

export const SUB_TO_WEBSITE_ACCESS = graphql(`
  subscription GetWebsiteAccess($businessId: uuid!, $websiteId: uuid!) {
    business_user(where: { business_id: { _eq: $businessId } }) {
      id
      user {
        id
        avatarUrl
        email
        displayName
        website_access_memberships(where: { website_id: { _eq: $websiteId } }) {
          id
          website_id
          connection_alive
          permissions
        }
      }
    }
  }
`)

export const SUB_TO_MY_WEBSITES_ACCESS = graphql(`
  subscription GetMyWebsiteAccess($userId: uuid!) {
    website_access(where: { user_id: { _eq: $userId } }) {
      id
      website_id
      connection_alive
      permissions
    }
  }
`)

export const GET_USER_BY_ID = graphql(`
  query GetUserById($userId: uuid!) {
    user(id: $userId) {
      id
      email
      displayName
      disabled
      avatarUrl
      defaultRole
      phoneNumber
      createdAt
      emailVerified
      metadata
      updatedAt
      phoneNumberVerified
      lastSeen
      user_businesses {
        id
        business {
          id
          name
        }
      }
    }
  }
`)

export const GET_WIDGET_LIST = graphql(`
  query WidgetList {
    web_widget {
      name
      description
      slug
      created_at
      updated_at
      site_type
    }
  }
`)

export const GET_WIDGET_INSTALLS_FOR_SLUG = graphql(`
  query getWidgetInstallsForSlug($widgetSlug: String!) {
    widget_install(where: { widget_slug: { _eq: $widgetSlug } }) {
      id
      created_at
      updated_at
      data
      site_identifier
      website {
        id
        duda_id
        business {
          id
          name
        }
      }
    }
  }
`)

export const WIDGET_INSTALL_SUB = graphql(`
  subscription WidgetInstallSub($widgetSlug: String!, $siteId: String!) {
    widget_install(where: { widget_slug: { _eq: $widgetSlug }, site_identifier: { _eq: $siteId } }) {
      id
      created_at
      updated_at
      data
      site_identifier
      website {
        id
        duda_id
        business {
          id
          name
        }
      }
      integrations {
        enabled
        slug
        created_at
        updated_at
        configuration
      }
    }
  }
`)
