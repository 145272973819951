import React, { ComponentProps, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { WidgetInstallSubSubscription } from '../../../gql/graphql'
import { useNhostFunction } from '../../../hooks'
import { SquareLinkingButton } from '../SquareLinkingButton'
import { TextInput, Button } from '@8thday/react'
import fastDeepEqual from 'fast-deep-equal'
import clsx from 'clsx'
import { useNhostClient } from '@nhost/react'
import { useApolloClient } from '@apollo/client'
import { UPDATE_PRODUCT_MAPPING } from '../../../graphql/mutations'
import { toast } from '../../../utils/toasts'
import { ArrowPathIcon, CheckCircleIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline'

interface Money {
  amount: string
  currency: string
}
interface SubscriptionPlanPhase {
  uid: string
  cadence:
    | 'DAILY'
    | 'WEEKLY'
    | 'EVERY_TWO_WEEKS'
    | 'THIRTY_DAYS'
    | 'MONTHLY'
    | 'SIXTY_DAYS'
    | 'EVERY_TWO_MONTHS'
    | 'NINETY_DAYS'
    | 'QUARTERLY'
    | 'EVERY_FOUR_MONTHS'
    | 'EVERY_SIX_MONTHS'
    | 'ANNUAL'
    | 'EVERY_TWO_YEARS'
  periods: number
  recurringPriceMoney: Money
  ordinal: string
}

interface SubscriptionPlan {
  type: 'SUBSCRIPTION_PLAN'
  id: string
  updatedAt: string
  version: string
  isDeleted: boolean
  presentAtAllLocations: boolean
  subscriptionPlanData: {
    name: string
    phases: SubscriptionPlanPhase[]
  }
}

export interface SquareCheckoutButtonProps extends ComponentProps<'div'> {
  widgetInstall: WidgetInstallSubSubscription['widget_install'][number]
}

const emptyProductMapping = {}

export const SquareCheckoutButton = ({ className = '', widgetInstall, ...props }: SquareCheckoutButtonProps) => {
  const nhost = useNhostClient()
  const apollo = useApolloClient()
  const { siteId, widgetSlug } = useParams()

  const squareIntegration = widgetInstall.integrations?.find((integration) => integration.slug === 'square')

  const { data } = useNhostFunction<{ success: boolean; subscriptionPlans?: SubscriptionPlan[] }>('public-site-data', {
    widgetSlug,
    siteId,
    dataType: 'subscription_plans',
  })
  // const { data: productData } = useNhostFunction('public-site-data', { widgetSlug, siteId, dataType: 'products' })
  // console.log(productData)

  const subscriptionPlans = data?.subscriptionPlans ?? []
  const productMapping = widgetInstall.data?.productMapping ?? emptyProductMapping
  const [editedProductMapping, setEditedProductMapping] = useState(productMapping)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setEditedProductMapping(productMapping)
  }, [productMapping])

  const dirty = useMemo(() => {
    return !fastDeepEqual(productMapping, editedProductMapping)
  }, [productMapping, editedProductMapping])

  const saveMapping = async () => {
    if (!dirty || saving) return

    setSaving(true)

    const cleanedMapping = Object.entries(editedProductMapping).reduce(
      (map, [key, val]) => ({ ...map, [key]: val || undefined }),
      {}
    )

    const res = await nhost.graphql
      .request(UPDATE_PRODUCT_MAPPING, { id: widgetInstall.id, productMapping: cleanedMapping }, { useAxios: false })
      .catch((err) => (err instanceof Error ? err : new Error(JSON.stringify(err))))

    if (res instanceof Error) {
      setSaving(false)
      return toast.error({ description: 'There was a problem saving, please try again.' })
    }

    await apollo.reFetchObservableQueries()

    setSaving(false)
  }

  return (
    <div className={`${className} flex flex-col min-h-0 grow`} {...props}>
      {!squareIntegration ? (
        <SquareLinkingButton className="self-center" widgetInstallId={widgetInstall.id}>
          Connect to Square
        </SquareLinkingButton>
      ) : (
        <div className="flex flex-col min-h-0 bg-white rounded p-4">
          <div className="flex justify-between mb-4">
            <h4>Assign Subscription Plans to a Product SKU</h4>
            <Button
              variant="primary"
              disabled={!dirty || saving}
              onClick={saveMapping}
              PreIcon={saving ? ArrowPathIcon : dirty ? CloudArrowUpIcon : CheckCircleIcon}
              spin={saving}
            >
              {dirty ? 'Save Updates' : 'Up to date'}
            </Button>
          </div>
          <div className="flex justify-between px-2 text-gray-500 text-sm font-semibold">
            <span>Subscription Plans</span>
            <span>Product SKUs</span>
          </div>
          <ul className="px-2 divide-y divide-gray-400 overflow-y-auto shadow-inner rounded bg-gray-50">
            {subscriptionPlans.map((plan) => (
              <li className="py-2 flex items-center" key={plan.id}>
                <div className="flex flex-col mr-2 sm:mr-4">
                  <span className="text-gray-700 font-semibold mb-1">{plan.subscriptionPlanData.name}</span>
                  <span className="text-gray-400 text-sm"></span>
                </div>
                <TextInput
                  id={`sku-${plan.id}`}
                  placeholder="Product SKU"
                  className="ml-auto flex items-center flex-row shrink-0"
                  inputClass={clsx({
                    '!ring-2 !ring-green-500':
                      (editedProductMapping[plan.id] ?? '') !== (productMapping[plan.id] ?? ''),
                  })}
                  collapseDescriptionArea
                  value={editedProductMapping[plan.id] ?? ''}
                  onChange={(e) => setEditedProductMapping((p) => ({ ...p, [plan.id]: e.target.value }))}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
