import { Site } from '@dudadev/partner-api/dist/types/lib/sites/types'
import {
  AdjustmentsHorizontalIcon,
  ArrowPathIcon,
  ArrowUturnLeftIcon,
  CheckIcon,
  ComputerDesktopIcon,
  ExclamationTriangleIcon,
  NoSymbolIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { useNhostClient } from '@nhost/react'
import React, { ComponentProps, Dispatch, SetStateAction, useMemo, useState } from 'react'
import { Users } from '../../gql/graphql'
import { CREATE_WEBSITE_ACCESS, DELETE_WEBSITE_ACCESS, UPDATE_WEBSITE_ACCESS } from '../../graphql'
import { arePrimitiveArraysEqual } from '../../utils/general'
import { validatePermissions } from '../../utils/permissions-matrix'
import { DeepPartial } from '../../utils/types'
import { Avatar } from '../atoms/Avatar'
import { IconButton, IconButtonProps } from '../atoms/IconButton'
import { WebsitePermissionsChecklist } from './WebsitePermissionsChecklist'

export interface TeamMemberProps extends ComponentProps<'div'> {
  user?: DeepPartial<Users>
  site?: Site
  readOnly?: boolean
}

export const TeamMember = ({ className = '', user, site, readOnly = false, ...props }: TeamMemberProps) => {
  const nhost = useNhostClient()

  const siteMembership = user?.website_access_memberships?.find((m) => m.website_id === site?.external_uid)

  const perms = siteMembership?.permissions

  const [loadingPermUpdate, setLoadingPermUpdate] = useState(false)
  const [showPerms, setShowPerms] = useState(false)
  const [editedPerms, setEditedPerms] = useState(perms ?? [])

  const dirtyPerms = useMemo(() => !arePrimitiveArraysEqual(editedPerms, perms), [editedPerms, perms])
  const validPerms = useMemo(() => {
    if (!siteMembership) {
      return !dirtyPerms || validatePermissions(editedPerms)
    }

    return (!!siteMembership.connection_alive || dirtyPerms) && validatePermissions(editedPerms)
  }, [editedPerms])

  const savePermissions = async () => {
    if (!editedPerms || loadingPermUpdate || readOnly) return

    if (!siteMembership) {
      const userId = user?.id
      const websiteId = site?.external_uid

      if (!userId || !websiteId) return

      setLoadingPermUpdate(true)
      await nhost.graphql.request(
        CREATE_WEBSITE_ACCESS,
        { userId, websiteId, permissions: editedPerms },
        { useAxios: false }
      )
      setLoadingPermUpdate(false)
      setShowPerms(false)
    } else if (editedPerms.length) {
      const id = siteMembership?.id

      if (!id) return

      setLoadingPermUpdate(true)
      await nhost.graphql.request(UPDATE_WEBSITE_ACCESS, { id, permissions: editedPerms }, { useAxios: false })
      setLoadingPermUpdate(false)
      setShowPerms(false)
    } else if (!editedPerms.length) {
      const id = siteMembership?.id

      if (!id) return

      setLoadingPermUpdate(true)
      await nhost.graphql.request(DELETE_WEBSITE_ACCESS, { id }, { useAxios: false })
      setLoadingPermUpdate(false)
      setShowPerms(false)
    }
  }

  return (
    <div className={`${className} rounded bg-white hover:bg-gray-50 focus-within:bg-gray-50`} {...props}>
      <div className="relative flex items-center space-x-3 px-6 py-5">
        <div className="flex-shrink-0">
          <Avatar className="h-10 w-10" avatarUrl={user?.avatarUrl} />
        </div>
        <div className="min-w-0 flex-1">
          <p className="flex items-center text-sm font-medium text-gray-900">
            {user?.displayName || user?.email}
            {siteMembership ? (
              !siteMembership.connection_alive ? (
                <ExclamationTriangleIcon className="text-yellow-500 h-4 w-4 inline ml-1" />
              ) : (
                <ComputerDesktopIcon className="text-green-500 h-4 w-4 inline ml-1" />
              )
            ) : (
              <NoSymbolIcon className="text-red-500 h-4 w-4 inline ml-1" />
            )}
          </p>
          {user?.displayName && <p className="truncate text-sm text-gray-500">{user?.email}</p>}
        </div>
        {!readOnly && (
          <div className="flex">
            <LeftIcon
              show={dirtyPerms && showPerms && !loadingPermUpdate}
              onClick={() => setEditedPerms(perms ?? [])}
            />
            <RightIcon
              show={showPerms}
              dirty={dirtyPerms}
              onSave={savePermissions}
              setShow={setShowPerms}
              hasMembership={!!siteMembership}
              loading={loadingPermUpdate}
              valid={validPerms}
            />
          </div>
        )}
      </div>
      {(showPerms || readOnly) && (
        <div className="px-6 py-4">
          <WebsitePermissionsChecklist
            readOnly={readOnly}
            id={user?.id}
            permissions={readOnly ? perms ?? [] : editedPerms}
            onPermsChange={setEditedPerms}
          />
        </div>
      )}
    </div>
  )
}

interface LeftIconProps extends Partial<IconButtonProps> {
  show: boolean
}

const LeftIcon = ({ show, onClick }: LeftIconProps) => {
  return show ? (
    <IconButton
      srLabel="Undo Permissions Changes"
      icon={ArrowUturnLeftIcon}
      colorClass="text-orange-400 hover:text-orange-500"
      onClick={onClick}
    />
  ) : null
}

interface RightIconProps extends Partial<IconButtonProps> {
  show: boolean
  dirty: boolean
  onSave(): void
  setShow: Dispatch<SetStateAction<boolean>>
  hasMembership: boolean
  loading: boolean
  valid: boolean
}

const RightIcon = ({ setShow, show, dirty, valid, onSave, hasMembership, loading }: RightIconProps) => {
  switch (true) {
    case !valid:
      return (
        <IconButton
          className="-mr-2.5 ml-4"
          srLabel="Fix Website Permissions"
          icon={ExclamationTriangleIcon}
          colorClass="text-yellow-600 hover:text-yellow-700 focus:text-yellow-700"
          onClick={() => setShow((s) => !s)}
        />
      )
    case loading:
      return (
        <IconButton
          className="-mr-2.5 ml-4"
          srLabel="Hide Website Permissions"
          icon={ArrowPathIcon}
          colorClass="text-primary-500"
          spin
        />
      )
    case show && dirty:
      return (
        <IconButton
          className="-mr-2.5 ml-4"
          srLabel="Save Website Permissions Changes"
          colorClass="text-green-500 hover:text-green-600 focus:text-green-600"
          icon={CheckIcon}
          onClick={onSave}
        />
      )
    case show && !dirty:
      return (
        <IconButton
          className="-mr-2.5 ml-4"
          srLabel="Hide Website Permissions"
          icon={XMarkIcon}
          onClick={() => setShow((s) => !s)}
        />
      )
    case hasMembership:
      return (
        <IconButton
          className="-mr-2.5 ml-4"
          srLabel="Edit Website Permissions"
          icon={AdjustmentsHorizontalIcon}
          onClick={() => setShow((s) => !s)}
        />
      )
    case !hasMembership:
      return (
        <IconButton
          className="-mr-2.5 ml-4"
          srLabel="Create Website Permissions"
          icon={PlusIcon}
          onClick={() => setShow((s) => !s)}
        />
      )
    default:
      return (
        <IconButton
          className="-mr-2.5 ml-4"
          srLabel="Show/Hide Website Permissions"
          icon={show ? XMarkIcon : PlusIcon}
          onClick={() => setShow((s) => !s)}
        />
      )
  }
}
