import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Dashboard } from '../components/molecules/Dashboard'
import { Profile } from '../components/molecules/Profile'
import { Integrations } from '../components/molecules/Integrations'
import { useUserDefaultRole } from '@nhost/react'
import { AllUsers } from '../components/molecules/AllUsers'
import { useBusinessId } from '../hooks'
import { WebsiteList } from '../components/molecules/WebsiteList'
import { WebsiteManager } from '../components/molecules/WebsiteManager'
import { SUPER_USER_ROLE } from '../utils/constants'
import { Home } from '../components/molecules/Home'
import { IntegrationSwitch } from '../components/molecules/IntegrationSwitch'
import { UserDashboard } from '../components/molecules/UserDashboard'
import { WidgetGrid } from '../components/molecules/WidgetGrid'
import { WidgetInstallsBySlug } from '../components/molecules/WidgetInstallsBySlug'
import { WidgetInstall } from '../components/molecules/WidgetInstall'

export const Router = () => {
  const isSuper = useUserDefaultRole() === SUPER_USER_ROLE
  const businessId = useBusinessId()

  return (
    <Routes>
      <Route path="/">
        <Route index element={<Home />} />
        <Route path="profile" element={<Profile />} />
        {isSuper && (
          <>
            <Route path="all-users">
              <Route index element={<AllUsers />} />
              <Route path=":userId" element={<UserDashboard />} />
            </Route>
            <Route path="web-widgets" element={<WidgetGrid />}>
              <Route path=":widgetSlug" element={<WidgetInstallsBySlug />}>
                <Route path=":siteId" element={<WidgetInstall />} />
              </Route>
            </Route>
          </>
        )}
        <Route path="b">
          <Route path=":businessId">
            <Route index element={<Dashboard />} />
            {/* <Route path="integrations" element={<Integrations />}>
              <Route path=":integrationSlug" element={<IntegrationSwitch />} />
            </Route> */}
            <Route path="websites" element={<WebsiteList />}>
              <Route path=":siteName" element={<WebsiteManager />} />
            </Route>
            <Route path="*" element={<Navigate replace to={`/b/${businessId}`} />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate replace to="/" />} />
      </Route>
    </Routes>
  )
}
