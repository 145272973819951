import { ApolloError, useApolloClient, useMutation } from '@apollo/client'
import { PlusIcon } from '@heroicons/react/20/solid'
import React, { useEffect, useRef, useState } from 'react'
import { CREATE_WIDGET_INSTALL } from '../../graphql'
import { Button, TextInput } from '@8thday/react'
import clsx from 'clsx'
import { ArrowPathIcon } from '@heroicons/react/24/outline'

export interface CreateWidgetInstallProps {
  className?: string
  widgetSlug: string
  existingInstalls?: { site_identifier?: string }[]
}

export const CreateWidgetInstall = ({
  className = '',
  widgetSlug,
  existingInstalls = [],
}: CreateWidgetInstallProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const apolloClient = useApolloClient()
  const [clicked, setClicked] = useState(false)
  const [installing, setInstalling] = useState(false)
  const [siteId, setSiteId] = useState('')
  const [validationMessage, setValidationMessage] = useState('')
  const [installWidget] = useMutation(CREATE_WIDGET_INSTALL, { variables: { widgetSlug, siteId, data: {} } })

  useEffect(() => {
    if (existingInstalls.some((e) => e.site_identifier === siteId)) {
      setValidationMessage('This widget is already installed for this site ID')
    } else {
      setValidationMessage('')
    }
  }, [siteId])

  useEffect(() => {
    if (clicked) {
      inputRef.current?.focus()
    }
  }, [clicked])

  if (!clicked) {
    return (
      <button
        className={`${className} h-full w-full flex-center text-primary-500 hover:text-primary-400`}
        onClick={() => setClicked((c) => !c)}
      >
        <PlusIcon className="h-8 w-8 mr-2" /> New Installation
      </button>
    )
  }

  return (
    <form
      className={clsx(className, 'grid grid-cols-2 gap-2')}
      onSubmit={async (e) => {
        e.preventDefault()
        e.stopPropagation()

        setInstalling(true)

        installWidget()
          .then(() => {
            setTimeout(() => {
              setClicked(false)
              setSiteId('')
              setInstalling(false)
              apolloClient.reFetchObservableQueries()
            }, 1000)
          })
          .catch((e: ApolloError) => {
            if (e.networkError) {
              setValidationMessage('network error: check your connection and try again.')
            } else if (e.graphQLErrors) {
              if (e.graphQLErrors[0].extensions.code === 'constraint-violation') {
                setValidationMessage('This widget is already installed for this site ID')
              } else if (e.graphQLErrors[0].extensions.code === 'permission-error') {
                setValidationMessage('The Widget App must first be installed on this site')
              } else {
                setValidationMessage(e.graphQLErrors[0].message)
              }
            }

            setInstalling(false)
          })
      }}
    >
      <TextInput
        ref={inputRef}
        value={siteId}
        onChange={(e) => setSiteId(e.target.value)}
        placeholder="paste Site ID here"
        className="col-span-full"
        errorMessage={validationMessage}
      />
      <Button variant="dismissive" onClick={() => setClicked(false)}>
        Cancel
      </Button>
      <Button type="submit" variant="primary" disabled={!siteId}>
        {installing ? <ArrowPathIcon className="h-5 w-5 animate-spin" /> : 'Install'}
      </Button>
    </form>
  )
}
