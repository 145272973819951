import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { useAuthQuery } from '@nhost/react-apollo'
import clsx from 'clsx'
import React, { ComponentProps } from 'react'
import { NavLink, Outlet, useOutletContext, useParams } from 'react-router-dom'
import { WidgetListQuery } from '../../gql/graphql'
import { GET_WIDGET_INSTALLS_FOR_SLUG } from '../../graphql'
import { Card } from '@8thday/react'
import { CreateWidgetInstall } from './CreateWidgetInstall'

export interface WidgetInstallsBySlugProps extends ComponentProps<'div'> {}

export const WidgetInstallsBySlug = ({ className = '', ...props }: WidgetInstallsBySlugProps) => {
  const { widgetSlug = '', siteId = '' } = useParams()
  const { widget } = useOutletContext<{ widget: WidgetListQuery['web_widget'][number] }>()
  const { data } = useAuthQuery(GET_WIDGET_INSTALLS_FOR_SLUG, { variables: { widgetSlug } })

  const businessName =
    siteId && data?.widget_install?.find((i) => i.site_identifier === siteId)?.website?.business?.name

  return (
    <>
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start mb-8">
        {!siteId ? (
          <NavLink className="flex items-center" to={`/web-widgets`}>
            <ArrowLeftIcon className="mr-1 h-5 w-5 inline" /> Widgets
          </NavLink>
        ) : (
          <NavLink className="flex items-center" to={`/web-widgets/${widgetSlug}`}>
            <ArrowLeftIcon className="mr-1 h-5 w-5 inline" /> Installations
          </NavLink>
        )}
        <h2 className="flex-center flex-col text-lg text-primary-800">
          <span>{widget?.name}</span>
          <span className="text-base text-gray-600">{siteId ? businessName : 'Installations'}</span>
        </h2>
      </div>
      {siteId ? (
        <Outlet />
      ) : (
        <ul className="flex flex-wrap items-stretch gap-4">
          {widgetSlug && (
            <Card as="li" className="grow max-w-sm">
              <CreateWidgetInstall existingInstalls={data?.widget_install ?? []} widgetSlug={widgetSlug} />
            </Card>
          )}
          {data?.widget_install?.map((wi) => (
            <Card key={wi.id} as="li" className="grow max-w-sm">
              <NavLink className="flex flex-col w-full h-full" to={wi.site_identifier}>
                <div
                  className={clsx(
                    'text-center mb-4',
                    !wi.website?.business?.name ? 'text-gray-500 italic' : 'text-primary-800'
                  )}
                >
                  {wi.website?.business?.name ?? 'External Website'}
                </div>
                <div className="flex flex-col">
                  <span className="text-gray-600 font-bold text-sm">Site ID:</span>
                  <span className="text-gray-800 italic text-sm">{wi.site_identifier}</span>
                </div>
              </NavLink>
            </Card>
          ))}
        </ul>
      )}
    </>
  )
}
