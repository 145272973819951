/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  mutation UpdateUserProfile($id: uuid!, $metadata: jsonb!, $displayName: String) {\n    updateUser(pk_columns: { id: $id }, _set: { displayName: $displayName }, _append: { metadata: $metadata }) {\n      id\n    }\n  }\n": types.UpdateUserProfileDocument,
    "\n  mutation updateWebsiteAccess($id: uuid!, $permissions: jsonb!) {\n    update_website_access_by_pk(pk_columns: { id: $id }, _set: { permissions: $permissions }) {\n      id\n    }\n  }\n": types.UpdateWebsiteAccessDocument,
    "\n  mutation createWebsiteAccess($websiteId: uuid!, $userId: uuid!, $permissions: jsonb!) {\n    insert_website_access_one(object: { website_id: $websiteId, user_id: $userId, permissions: $permissions }) {\n      id\n    }\n  }\n": types.CreateWebsiteAccessDocument,
    "\n  mutation deleteWebsiteAccess($id: uuid!) {\n    delete_website_access_by_pk(id: $id) {\n      id\n    }\n  }\n": types.DeleteWebsiteAccessDocument,
    "\n  mutation CreateUser($email: citext!, $businessId: uuid!) {\n    insertUser(\n      object: {\n        email: $email\n        locale: \"en\"\n        roles: { data: [{ role: \"user\" }, { role: \"me\" }] }\n        user_businesses: { data: { business_id: $businessId } }\n      }\n    ) {\n      id\n    }\n  }\n": types.CreateUserDocument,
    "\n  mutation CreateBusinessUser($userId: uuid!, $businessId: uuid!) {\n    insert_business_user_one(object: { user_id: $userId, business_id: $businessId }) {\n      id\n    }\n  }\n": types.CreateBusinessUserDocument,
    "\n  mutation CreateBusiness($name: String!) {\n    insert_business_one(object: { name: $name }) {\n      id\n      name\n      created_at\n      updated_at\n    }\n  }\n": types.CreateBusinessDocument,
    "\n  mutation DeleteBusinessUser($userId: uuid!, $businessId: uuid!) {\n    delete_business_user(where: { user_id: { _eq: $userId }, business_id: { _eq: $businessId } }) {\n      affected_rows\n    }\n  }\n": types.DeleteBusinessUserDocument,
    "\n  mutation createWidgetInstall($widgetSlug: String!, $siteId: String!, $data: jsonb!) {\n    insert_widget_install_one(object: { widget_slug: $widgetSlug, site_identifier: $siteId, data: $data }) {\n      id\n    }\n  }\n": types.CreateWidgetInstallDocument,
    "\n  mutation updateProductMapping($id: uuid!, $productMapping: jsonb!) {\n    update_widget_install_by_pk(pk_columns: { id: $id }, _append: { data: { productMapping: $productMapping } }) {\n      id\n    }\n  }\n": types.UpdateProductMappingDocument,
    "\n  query GetBusinesses {\n    business {\n      id\n      name\n    }\n  }\n": types.GetBusinessesDocument,
    "\n  query GetBusinessById($businessId: uuid!) {\n    business_by_pk(id: $businessId) {\n      id\n      name\n    }\n  }\n": types.GetBusinessByIdDocument,
    "\n  subscription SubBusinessById($businessId: uuid!) {\n    business_by_pk(id: $businessId) {\n      id\n      name\n      integrations {\n        id\n        enabled\n        slug\n      }\n    }\n  }\n": types.SubBusinessByIdDocument,
    "\n  query AllUsers {\n    users(order_by: { email: asc }) {\n      id\n      email\n      displayName\n      disabled\n      avatarUrl\n      defaultRole\n      emailVerified\n      lastSeen\n    }\n  }\n": types.AllUsersDocument,
    "\n  query AllUsersNotInBusiness($businessId: uuid!) {\n    users(\n      order_by: { email: asc }\n      where: { defaultRole: { _eq: \"user\" }, _not: { user_businesses: { business_id: { _eq: $businessId } } } }\n    ) {\n      id\n      email\n      displayName\n      disabled\n      avatarUrl\n      defaultRole\n      emailVerified\n      lastSeen\n    }\n  }\n": types.AllUsersNotInBusinessDocument,
    "\n  query BusinessMembers($businessId: uuid!) {\n    users(\n      order_by: { email: asc }\n      where: {\n        _or: [{ user_businesses: { business_id: { _eq: $businessId } } }, { defaultRole: { _eq: \"superuser\" } }]\n      }\n    ) {\n      id\n      email\n      displayName\n      disabled\n      avatarUrl\n      defaultRole\n      emailVerified\n      lastSeen\n    }\n  }\n": types.BusinessMembersDocument,
    "\n  subscription GetWebsiteAccess($businessId: uuid!, $websiteId: uuid!) {\n    business_user(where: { business_id: { _eq: $businessId } }) {\n      id\n      user {\n        id\n        avatarUrl\n        email\n        displayName\n        website_access_memberships(where: { website_id: { _eq: $websiteId } }) {\n          id\n          website_id\n          connection_alive\n          permissions\n        }\n      }\n    }\n  }\n": types.GetWebsiteAccessDocument,
    "\n  subscription GetMyWebsiteAccess($userId: uuid!) {\n    website_access(where: { user_id: { _eq: $userId } }) {\n      id\n      website_id\n      connection_alive\n      permissions\n    }\n  }\n": types.GetMyWebsiteAccessDocument,
    "\n  query GetUserById($userId: uuid!) {\n    user(id: $userId) {\n      id\n      email\n      displayName\n      disabled\n      avatarUrl\n      defaultRole\n      phoneNumber\n      createdAt\n      emailVerified\n      metadata\n      updatedAt\n      phoneNumberVerified\n      lastSeen\n      user_businesses {\n        id\n        business {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.GetUserByIdDocument,
    "\n  query WidgetList {\n    web_widget {\n      name\n      description\n      slug\n      created_at\n      updated_at\n      site_type\n    }\n  }\n": types.WidgetListDocument,
    "\n  query getWidgetInstallsForSlug($widgetSlug: String!) {\n    widget_install(where: { widget_slug: { _eq: $widgetSlug } }) {\n      id\n      created_at\n      updated_at\n      data\n      site_identifier\n      website {\n        id\n        duda_id\n        business {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.GetWidgetInstallsForSlugDocument,
    "\n  subscription WidgetInstallSub($widgetSlug: String!, $siteId: String!) {\n    widget_install(where: { widget_slug: { _eq: $widgetSlug }, site_identifier: { _eq: $siteId } }) {\n      id\n      created_at\n      updated_at\n      data\n      site_identifier\n      website {\n        id\n        duda_id\n        business {\n          id\n          name\n        }\n      }\n      integrations {\n        enabled\n        slug\n        created_at\n        updated_at\n        configuration\n      }\n    }\n  }\n": types.WidgetInstallSubDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUserProfile($id: uuid!, $metadata: jsonb!, $displayName: String) {\n    updateUser(pk_columns: { id: $id }, _set: { displayName: $displayName }, _append: { metadata: $metadata }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserProfile($id: uuid!, $metadata: jsonb!, $displayName: String) {\n    updateUser(pk_columns: { id: $id }, _set: { displayName: $displayName }, _append: { metadata: $metadata }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateWebsiteAccess($id: uuid!, $permissions: jsonb!) {\n    update_website_access_by_pk(pk_columns: { id: $id }, _set: { permissions: $permissions }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation updateWebsiteAccess($id: uuid!, $permissions: jsonb!) {\n    update_website_access_by_pk(pk_columns: { id: $id }, _set: { permissions: $permissions }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createWebsiteAccess($websiteId: uuid!, $userId: uuid!, $permissions: jsonb!) {\n    insert_website_access_one(object: { website_id: $websiteId, user_id: $userId, permissions: $permissions }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation createWebsiteAccess($websiteId: uuid!, $userId: uuid!, $permissions: jsonb!) {\n    insert_website_access_one(object: { website_id: $websiteId, user_id: $userId, permissions: $permissions }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteWebsiteAccess($id: uuid!) {\n    delete_website_access_by_pk(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation deleteWebsiteAccess($id: uuid!) {\n    delete_website_access_by_pk(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateUser($email: citext!, $businessId: uuid!) {\n    insertUser(\n      object: {\n        email: $email\n        locale: \"en\"\n        roles: { data: [{ role: \"user\" }, { role: \"me\" }] }\n        user_businesses: { data: { business_id: $businessId } }\n      }\n    ) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateUser($email: citext!, $businessId: uuid!) {\n    insertUser(\n      object: {\n        email: $email\n        locale: \"en\"\n        roles: { data: [{ role: \"user\" }, { role: \"me\" }] }\n        user_businesses: { data: { business_id: $businessId } }\n      }\n    ) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateBusinessUser($userId: uuid!, $businessId: uuid!) {\n    insert_business_user_one(object: { user_id: $userId, business_id: $businessId }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateBusinessUser($userId: uuid!, $businessId: uuid!) {\n    insert_business_user_one(object: { user_id: $userId, business_id: $businessId }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateBusiness($name: String!) {\n    insert_business_one(object: { name: $name }) {\n      id\n      name\n      created_at\n      updated_at\n    }\n  }\n"): (typeof documents)["\n  mutation CreateBusiness($name: String!) {\n    insert_business_one(object: { name: $name }) {\n      id\n      name\n      created_at\n      updated_at\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteBusinessUser($userId: uuid!, $businessId: uuid!) {\n    delete_business_user(where: { user_id: { _eq: $userId }, business_id: { _eq: $businessId } }) {\n      affected_rows\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteBusinessUser($userId: uuid!, $businessId: uuid!) {\n    delete_business_user(where: { user_id: { _eq: $userId }, business_id: { _eq: $businessId } }) {\n      affected_rows\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createWidgetInstall($widgetSlug: String!, $siteId: String!, $data: jsonb!) {\n    insert_widget_install_one(object: { widget_slug: $widgetSlug, site_identifier: $siteId, data: $data }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation createWidgetInstall($widgetSlug: String!, $siteId: String!, $data: jsonb!) {\n    insert_widget_install_one(object: { widget_slug: $widgetSlug, site_identifier: $siteId, data: $data }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateProductMapping($id: uuid!, $productMapping: jsonb!) {\n    update_widget_install_by_pk(pk_columns: { id: $id }, _append: { data: { productMapping: $productMapping } }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation updateProductMapping($id: uuid!, $productMapping: jsonb!) {\n    update_widget_install_by_pk(pk_columns: { id: $id }, _append: { data: { productMapping: $productMapping } }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetBusinesses {\n    business {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query GetBusinesses {\n    business {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetBusinessById($businessId: uuid!) {\n    business_by_pk(id: $businessId) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query GetBusinessById($businessId: uuid!) {\n    business_by_pk(id: $businessId) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription SubBusinessById($businessId: uuid!) {\n    business_by_pk(id: $businessId) {\n      id\n      name\n      integrations {\n        id\n        enabled\n        slug\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription SubBusinessById($businessId: uuid!) {\n    business_by_pk(id: $businessId) {\n      id\n      name\n      integrations {\n        id\n        enabled\n        slug\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AllUsers {\n    users(order_by: { email: asc }) {\n      id\n      email\n      displayName\n      disabled\n      avatarUrl\n      defaultRole\n      emailVerified\n      lastSeen\n    }\n  }\n"): (typeof documents)["\n  query AllUsers {\n    users(order_by: { email: asc }) {\n      id\n      email\n      displayName\n      disabled\n      avatarUrl\n      defaultRole\n      emailVerified\n      lastSeen\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AllUsersNotInBusiness($businessId: uuid!) {\n    users(\n      order_by: { email: asc }\n      where: { defaultRole: { _eq: \"user\" }, _not: { user_businesses: { business_id: { _eq: $businessId } } } }\n    ) {\n      id\n      email\n      displayName\n      disabled\n      avatarUrl\n      defaultRole\n      emailVerified\n      lastSeen\n    }\n  }\n"): (typeof documents)["\n  query AllUsersNotInBusiness($businessId: uuid!) {\n    users(\n      order_by: { email: asc }\n      where: { defaultRole: { _eq: \"user\" }, _not: { user_businesses: { business_id: { _eq: $businessId } } } }\n    ) {\n      id\n      email\n      displayName\n      disabled\n      avatarUrl\n      defaultRole\n      emailVerified\n      lastSeen\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BusinessMembers($businessId: uuid!) {\n    users(\n      order_by: { email: asc }\n      where: {\n        _or: [{ user_businesses: { business_id: { _eq: $businessId } } }, { defaultRole: { _eq: \"superuser\" } }]\n      }\n    ) {\n      id\n      email\n      displayName\n      disabled\n      avatarUrl\n      defaultRole\n      emailVerified\n      lastSeen\n    }\n  }\n"): (typeof documents)["\n  query BusinessMembers($businessId: uuid!) {\n    users(\n      order_by: { email: asc }\n      where: {\n        _or: [{ user_businesses: { business_id: { _eq: $businessId } } }, { defaultRole: { _eq: \"superuser\" } }]\n      }\n    ) {\n      id\n      email\n      displayName\n      disabled\n      avatarUrl\n      defaultRole\n      emailVerified\n      lastSeen\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription GetWebsiteAccess($businessId: uuid!, $websiteId: uuid!) {\n    business_user(where: { business_id: { _eq: $businessId } }) {\n      id\n      user {\n        id\n        avatarUrl\n        email\n        displayName\n        website_access_memberships(where: { website_id: { _eq: $websiteId } }) {\n          id\n          website_id\n          connection_alive\n          permissions\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription GetWebsiteAccess($businessId: uuid!, $websiteId: uuid!) {\n    business_user(where: { business_id: { _eq: $businessId } }) {\n      id\n      user {\n        id\n        avatarUrl\n        email\n        displayName\n        website_access_memberships(where: { website_id: { _eq: $websiteId } }) {\n          id\n          website_id\n          connection_alive\n          permissions\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription GetMyWebsiteAccess($userId: uuid!) {\n    website_access(where: { user_id: { _eq: $userId } }) {\n      id\n      website_id\n      connection_alive\n      permissions\n    }\n  }\n"): (typeof documents)["\n  subscription GetMyWebsiteAccess($userId: uuid!) {\n    website_access(where: { user_id: { _eq: $userId } }) {\n      id\n      website_id\n      connection_alive\n      permissions\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetUserById($userId: uuid!) {\n    user(id: $userId) {\n      id\n      email\n      displayName\n      disabled\n      avatarUrl\n      defaultRole\n      phoneNumber\n      createdAt\n      emailVerified\n      metadata\n      updatedAt\n      phoneNumberVerified\n      lastSeen\n      user_businesses {\n        id\n        business {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUserById($userId: uuid!) {\n    user(id: $userId) {\n      id\n      email\n      displayName\n      disabled\n      avatarUrl\n      defaultRole\n      phoneNumber\n      createdAt\n      emailVerified\n      metadata\n      updatedAt\n      phoneNumberVerified\n      lastSeen\n      user_businesses {\n        id\n        business {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query WidgetList {\n    web_widget {\n      name\n      description\n      slug\n      created_at\n      updated_at\n      site_type\n    }\n  }\n"): (typeof documents)["\n  query WidgetList {\n    web_widget {\n      name\n      description\n      slug\n      created_at\n      updated_at\n      site_type\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getWidgetInstallsForSlug($widgetSlug: String!) {\n    widget_install(where: { widget_slug: { _eq: $widgetSlug } }) {\n      id\n      created_at\n      updated_at\n      data\n      site_identifier\n      website {\n        id\n        duda_id\n        business {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getWidgetInstallsForSlug($widgetSlug: String!) {\n    widget_install(where: { widget_slug: { _eq: $widgetSlug } }) {\n      id\n      created_at\n      updated_at\n      data\n      site_identifier\n      website {\n        id\n        duda_id\n        business {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription WidgetInstallSub($widgetSlug: String!, $siteId: String!) {\n    widget_install(where: { widget_slug: { _eq: $widgetSlug }, site_identifier: { _eq: $siteId } }) {\n      id\n      created_at\n      updated_at\n      data\n      site_identifier\n      website {\n        id\n        duda_id\n        business {\n          id\n          name\n        }\n      }\n      integrations {\n        enabled\n        slug\n        created_at\n        updated_at\n        configuration\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription WidgetInstallSub($widgetSlug: String!, $siteId: String!) {\n    widget_install(where: { widget_slug: { _eq: $widgetSlug }, site_identifier: { _eq: $siteId } }) {\n      id\n      created_at\n      updated_at\n      data\n      site_identifier\n      website {\n        id\n        duda_id\n        business {\n          id\n          name\n        }\n      }\n      integrations {\n        enabled\n        slug\n        created_at\n        updated_at\n        configuration\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;