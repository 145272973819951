import {
  ArrowLeftOnRectangleIcon,
  ArrowTopRightOnSquareIcon,
  CalendarDaysIcon,
  ChevronRightIcon,
  ComputerDesktopIcon,
  HomeIcon,
  UserGroupIcon,
  Cog8ToothIcon,
} from '@heroicons/react/24/outline'
import { useNhostClient, useSignOut, useUserData } from '@nhost/react'
import clsx from 'clsx'
import React, { forwardRef, MouseEvent, ReactNode, useState } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { useBusinessId } from '../hooks'
import { SUPER_USER_ROLE } from '../utils/constants'
import { openSSOLink } from '../utils/general'
import { IconType } from '../utils/types'

const baseLinkClasses = 'group flex items-center px-2 py-2 text-base md:text-sm font-medium rounded-md'
const buttonLinkClasses = 'w-full hover:bg-primary-600 hover:bg-opacity-75'
const buttonLinkIconClasses = 'mr-4 md:mr-3 h-6 w-6 flex-shrink-0 text-primary-300'

export interface NavLinkListProps {
  onClick?(event?: MouseEvent): void
  businesses: { id: string; name: string }[]
}

export const NavLinkList = ({ onClick, businesses }: NavLinkListProps) => {
  const nhost = useNhostClient()
  const businessId = useBusinessId()
  const [showOtherBusinesses, setShowOtherBusinesses] = useState(!businessId)
  const user = useUserData()

  const isSuper = user?.defaultRole === SUPER_USER_ROLE

  const business = businesses.find((b) => b.id === businessId)
  const unselectedBusinesses = businesses.filter((b) => b.id !== businessId)

  const { signOut } = useSignOut()

  return (
    <nav className="mt-5 space-y-1 px-2 md:flex-1">
      {isSuper && (
        <>
          <Link icon={UserGroupIcon} to="/all-users" onClick={onClick}>
            User Management
          </Link>
          <button
            className={clsx(baseLinkClasses, buttonLinkClasses, 'text-white')}
            onClick={() => {
              openSSOLink({ nhost })
            }}
          >
            <ArrowTopRightOnSquareIcon className={buttonLinkIconClasses} />
            Duda Dashboard
          </button>
          <Link icon={Cog8ToothIcon} to="/web-widgets" end={false} onClick={onClick}>
            Widget Management
          </Link>
          <hr className="w-full border-primary-800" />
        </>
      )}
      <Link
        icon={HomeIcon}
        onClick={(e) => {
          if (!businessId) {
            e.preventDefault()
            e.stopPropagation()
          } else {
            onClick?.(e)
            setShowOtherBusinesses(false)
          }
        }}
        to={`/b/${businessId}`}
      >
        {business?.name || 'Businesses'}
        {!!unselectedBusinesses.length && (
          <ChevronRightIcon
            tabIndex={0}
            role="button"
            className={clsx(
              showOtherBusinesses || !businessId ? 'text-gray-400 rotate-90' : 'text-gray-300',
              'ml-auto h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
            )}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              if (businessId) {
                setShowOtherBusinesses((s) => !s)
              }
            }}
          />
        )}
      </Link>
      {(showOtherBusinesses || !businessId) &&
        unselectedBusinesses.map((b) => (
          <Link
            key={b.name}
            subLink
            to={`/b/${b.id}`}
            onClick={() => {
              onClick?.()
              setShowOtherBusinesses(false)
            }}
          >
            {b.name}
          </Link>
        ))}
      {businessId && (
        <>
          {/* <Link icon={CalendarDaysIcon} to={`/b/${businessId}/integrations`} onClick={onClick}>
            Integrations
          </Link> */}
          <Link icon={ComputerDesktopIcon} to={`/b/${businessId}/websites`} onClick={onClick}>
            Website Management
          </Link>
        </>
      )}
      <hr className="w-full border-primary-800" />
      <button
        className={clsx(baseLinkClasses, buttonLinkClasses, 'text-primary-200')}
        onClick={(e) => {
          onClick?.(e)
          signOut()
        }}
      >
        <ArrowLeftOnRectangleIcon className={buttonLinkIconClasses} aria-hidden="true" />
        Logout
      </button>
    </nav>
  )
}

interface LinkProps extends NavLinkProps {
  to: string
  external?: boolean
  icon?: IconType
  children?: ReactNode
  subLink?: boolean
  end?: boolean
}

const Link = forwardRef(
  ({ onClick, to, external = false, icon: Icon, subLink = false, end = true, children }: LinkProps, ref: any) => {
    return external ? (
      <a
        className={clsx(
          baseLinkClasses,
          subLink
            ? 'text-primary-100 hover:bg-primary-600 hover:bg-opacity-75 pl-11'
            : 'text-white hover:bg-primary-600 hover:bg-opacity-75'
        )}
        href={to}
        target="_blank"
        rel="noreferrer noopener"
        onClick={onClick}
        ref={ref}
      >
        {Icon && <Icon className="mr-4 md:mr-3 h-6 w-6 flex-shrink-0 text-primary-300" aria-hidden="true" />}
        {children}
      </a>
    ) : (
      <NavLink
        to={to}
        end={end}
        className={({ isActive }) =>
          clsx(
            baseLinkClasses,
            subLink ? 'text-primary-100 pl-11' : 'text-white',
            isActive ? 'bg-primary-800' : 'hover:bg-primary-600 hover:bg-opacity-75'
          )
        }
        onClick={onClick}
        ref={ref}
      >
        {Icon && <Icon className="mr-4 md:mr-3 h-6 w-6 flex-shrink-0 text-primary-300" aria-hidden="true" />}
        {children}
      </NavLink>
    )
  }
)
