import { Button, ButtonProps } from '@8thday/react'
import { useNhostClient } from '@nhost/react'
import React, { forwardRef, MouseEvent } from 'react'
import { useBusinessId } from '../../hooks'
import { toast } from '../../utils/toasts'

export interface SquareLinkingButtonProps extends Omit<ButtonProps, 'ref'> {
  widgetInstallId?: string
}

export const SquareLinkingButton = forwardRef<HTMLButtonElement, SquareLinkingButtonProps>(
  ({ className = '', widgetInstallId, ...props }, ref) => {
    const businessId = useBusinessId() || undefined // not super necessary, but helpful for backend uuid type mismatches
    const nhost = useNhostClient()

    const createIntegration = async (e: MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()

      if (!widgetInstallId && !businessId) return

      const result = await nhost.functions.call<{ url: string }>(
        'integrations/link',
        { businessId, widgetInstallId, slug: 'square', payload: { currentUrl: window.location.href } },
        { useAxios: false }
      )

      if (result.res?.data?.url) {
        window.open(result.res.data.url, '_self')
      } else {
        toast.error({ message: 'Authorization Failed to Initiate', description: result.error?.message })
      }
    }

    return (
      <Button
        disabled={!widgetInstallId && !businessId}
        ref={ref}
        className={`${className}`}
        {...props}
        onClick={createIntegration}
      />
    )
  }
)
