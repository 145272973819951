import { ArrowPathIcon, ArrowLeftIcon, EyeIcon, PencilIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useNhostClient, useUserData, useUserDefaultRole } from '@nhost/react'
import React, { ComponentProps, useEffect, useState } from 'react'
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom'
import { useBusinessId, useNhostFunction } from '../../hooks'
import { SUPER_USER_ROLE } from '../../utils/constants'
import { openNewTab, openSSOLink } from '../../utils/general'
import { Button, TextInput } from '@8thday/react'
import { IconButton } from '../atoms/IconButton'
import { SUB_TO_MY_WEBSITES_ACCESS } from '../../graphql'
import { hasDudaPermission } from '../../utils/permissions-matrix'
import { Site } from '@dudadev/partner-api/dist/types/lib/sites/types'
import { useSubscription } from '@apollo/client'

export interface WebsiteListProps extends ComponentProps<'div'> {}

export const WebsiteList = ({ className = '', ...props }: WebsiteListProps) => {
  const user = useUserData()
  const isSuper = user?.defaultRole === SUPER_USER_ROLE
  const userId = user?.id
  const [site_name, setSite_name] = useState('')
  const { data: accessData } = useSubscription(SUB_TO_MY_WEBSITES_ACCESS, {
    variables: { userId },
    skip: !userId || isSuper,
  })

  const myAccess = accessData?.website_access ?? []

  const nhost = useNhostClient()
  const businessId = useBusinessId()
  const { siteName } = useParams()

  const { data, error, loading, refetch } = useNhostFunction<{ sites: Site[] }>('duda/website/list', { businessId })

  const activeSite = data?.sites?.find((site) => site.site_name === siteName)

  const goTo = useNavigate()

  useEffect(() => {
    if ((data || error) && siteName && !activeSite) {
      goTo(`/b/${businessId}/websites`)
    }
  }, [data, siteName, activeSite, businessId])

  return (
    <div className={`${className} p-4 h-screen flex flex-col`} {...props}>
      <h2 className="flex items-center text-lg font-medium text-gray-700 mb-4">
        <NavLink to={`/b/${businessId}/websites`}>
          {activeSite && <ArrowLeftIcon className="mr-1 h-5 w-5 inline" />} Websites
        </NavLink>
        <IconButton
          className={!activeSite ? 'mx-2' : 'ml-auto'}
          srLabel="refresh website data"
          icon={ArrowPathIcon}
          spin={loading}
          onClick={() => refetch()}
        />
        {!activeSite && isSuper && (
          <>
            <TextInput
              className="ml-auto mr-2 placeholder:italic"
              collapseDescriptionArea
              value={site_name}
              placeholder="Site ID (optional)"
              onChange={(e) => setSite_name(e.target.value)}
            />
            <Button
              PreIcon={PlusIcon}
              onClick={async () => {
                const result = await nhost.functions
                  .call('duda/website/create', { businessId, site_name }, { useAxios: false })
                  .catch((err) => (err instanceof Error ? err : new Error(JSON.stringify(err))))

                if (result instanceof Error) {
                  return console.error('oops', result)
                }

                refetch()
              }}
            >
              Create Website
            </Button>
          </>
        )}
      </h2>
      {activeSite ? (
        <Outlet context={activeSite} />
      ) : (
        <ul role="list" className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 lg:grid-cols-2 xl:grid-cols-3">
          {data?.sites?.map((site) => {
            const sitePermissions = myAccess.find((w) => w.website_id === site.external_uid)?.permissions ?? []

            return (
              <li key={site.site_name} className="hover:scale-105 duration-150">
                <NavLink to={`${site.site_name}`} className="col-span-1 flex rounded-md shadow-sm group">
                  <div
                    className="flex-shrink-0 flex w-24 h-20 items-stretch text-white text-sm font-medium rounded-l-md bg-cover border-t border-l border-b border-gray-200"
                    style={{ backgroundImage: `url(${site.thumbnail_url})` }}
                  ></div>
                  <div className="flex flex-1 self-stretch items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white pr-2">
                    <div className="flex-1 self-stretch flex flex-col justify-evenly truncate mx-2 py-2 text-sm">
                      <span className="font-medium text-gray-900 group-hover:text-primary-700 transition-colors truncate">
                        <abbr
                          className="no-underline"
                          title={site.site_domain ?? site.site_default_domain ?? site.site_name}
                        >
                          {site.site_domain ?? site.site_default_domain ?? site.site_name}
                        </abbr>
                      </span>
                      <PublishStatus status={site.publish_status} />
                    </div>
                    {site.preview_site_url && (
                      <div className="flex-shrink-0 ml-2">
                        <IconButton
                          icon={EyeIcon}
                          srLabel="Preview Site"
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            openNewTab(site.preview_site_url)
                          }}
                        />
                      </div>
                    )}
                    {(isSuper ||
                      hasDudaPermission(
                        ['BLOG', 'SITE_COMMENTS', 'LIMITED_EDITING', 'EDIT'],
                        'OR',
                        sitePermissions
                      )) && (
                      <div className="flex-shrink-0 ml-2">
                        <IconButton
                          icon={PencilIcon}
                          srLabel="Edit Site"
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            openSSOLink({ nhost, site_name: site.site_name, target: 'EDITOR' })
                          }}
                        />
                      </div>
                    )}
                  </div>
                </NavLink>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

const PublishStatus = ({ status }: { status?: string }) => {
  switch (status) {
    case 'PUBLISHED':
      return <p className="text-green-500">Published</p>
    case 'NOT_PUBLISHED_YET':
      return <p className="text-yellow-500">Not Published Yet</p>
    case 'UNPUBLISHED':
    default:
      return <p className="text-red-400">Unpublished</p>
  }
}
