import { ArrowPathIcon, CheckIcon, FaceFrownIcon, RocketLaunchIcon } from '@heroicons/react/24/outline'
import { useSignInEmailPasswordless } from '@nhost/react'
import React, { useState } from 'react'
import { Button } from '@8thday/react'
import { CompanyLogo } from '../atoms/CompanyLogo'
import { CompanyName } from '../atoms/CompanyName'

const logoColor = 'text-primary-300'

export const LoginScreen = () => {
  const [email, setEmail] = useState('')

  const { signInEmailPasswordless, isSuccess, isError, isLoading } = useSignInEmailPasswordless()

  return (
    <main className="h-screen bg-gray-50">
      <div className="flex min-h-full flex-col justify-center py-6 sm:py-12 sm:px-6 lg:px-8">
        <div className="mx-1 sm:mx-auto sm:w-full sm:max-w-md">
          <CompanyName colorClass="text-primary-800" sizeClass="text-4xl" className="text-center mb-2" />
          <CompanyLogo colorClass={logoColor} type="sky" />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-primary-800">
            Sign in to your account
          </h2>
          <div className="py-8 px-4">
            <form
              className="space-y-6"
              onSubmit={(e) => {
                e.preventDefault()
                e.stopPropagation()

                signInEmailPasswordless(email)
              }}
            >
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              {/* <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex items-center">
                <div className="text-sm">
                  <a href="#" className="font-medium text-primary-600 hover:text-primary-500">
                    Forgot your password?
                  </a>
                </div>
              </div> */}

              <div>
                <Button
                  type="submit"
                  variant="primary"
                  className="w-full justify-center"
                  spin={isLoading}
                  PostIcon={
                    isLoading ? ArrowPathIcon : isSuccess ? CheckIcon : isError ? FaceFrownIcon : RocketLaunchIcon
                  }
                >
                  Sign in
                </Button>
              </div>
              {isError && (
                <p className="text-red-600 mt-4">
                  There was a problem sending your Magic Sign In Link. Please check your email and try again.
                </p>
              )}
              {isSuccess && <p className="text-emerald-500 mt-4">A Magic Sign In Link has been sent to your email!</p>}
            </form>
          </div>
          <CompanyLogo type="waves" colorClass={logoColor} />
        </div>
      </div>
    </main>
  )
}
