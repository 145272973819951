import { NhostClient } from '@nhost/react'
import { SUPER_USER_ROLE } from './constants'

export const openNewTab = (url?: string) => {
  if (url) {
    window.open(url, '_blank', 'noreferrer,noopener')
  }
}

export const openSSOLink = async ({
  nhost,
  site_name,
  target,
}: {
  nhost: NhostClient
  site_name?: string
  target?: 'STATS' | 'EDITOR' | 'RESET_SITE' | 'SWITCH_TEMPLATE' | ''
}) => {
  const stuff = await nhost.functions
    .call<any>('duda/sso', { site_name, target }, { useAxios: false })
    .catch((err) => (err instanceof Error ? err : new Error(JSON.stringify(err))))

  if (stuff instanceof Error) {
    return console.error(stuff)
  }

  openNewTab(stuff.res?.data?.account?.url)
}

export const arePrimitiveArraysEqual = (arr1 = [], arr2 = []) => {
  if (arr1.length !== arr2.length) {
    return false
  }

  const arr1Map = arr1.reduce((map, item) => ({ ...map, [item]: true }), {})

  return arr2.every((item) => arr1Map[item])
}

export const displayRole = (role: string) => {
  switch (role) {
    case SUPER_USER_ROLE:
      return 'Super User'
    case 'user':
    default:
      return 'User'
  }
}

export const displayBusinessRole = (role: string) => {
  switch (role) {
    case SUPER_USER_ROLE:
      return 'Super User'
    case 'user':
    default:
      return 'Member'
  }
}

export const formatDate = (date: string | Date) => {
  if (!date) {
    return ''
  }

  const dateObj = new Date(date)

  if (dateObj.toString() === 'Invalid Date') {
    return date.toString()
  }

  return dateObj.toLocaleDateString()
}
export const formatTime = (date: string | Date) => {
  if (!date) {
    return ''
  }

  const dateObj = new Date(date)

  if (dateObj.toString() === 'Invalid Date') {
    return date.toString()
  }

  return dateObj.toLocaleTimeString()
}
export const formatDateTime = (date: string | Date) => {
  if (!date) {
    return ''
  }

  const dateObj = new Date(date)

  if (dateObj.toString() === 'Invalid Date') {
    return date.toString()
  }

  return dateObj.toLocaleString()
}
